<template>
  <div class="knowledgeBase">
    <el-scrollbar
      wrap-class="scrollbar-wrapper"
      :class="[isFixed?'sidebar-container isFixedTop':'sidebar-container']"
    >
      <el-menu
        router
        unique-opened
        :default-active="activeMenu"
        active-text-color="#fff"
        @select="getKnowledgeInfo"
      >
        <template v-for="column in columnList">
          <el-submenu
            :index="column.index"
            :key="column.id"
          >
            <template slot="title">
              <span class="columnFir">{{column.name}}</span>
            </template>
            <el-menu-item
              v-for="article in column.articleList"
              :key="article.id"
              :index="'/knowledgeBase/'+article.index"
            >
              {{article.title}}
            </el-menu-item>
            <el-submenu
              :index="columnSec.index"
              :key="columnSec.id"
              v-for="columnSec in column.columnList"
            >
              <template slot="title">
                <span class="columnSec">{{columnSec.name}}</span>
              </template>
              <el-menu-item
                v-for="articleSec in columnSec.articleList"
                :key="articleSec.id"
                :index="'/knowledgeBase/'+articleSec.index"
              >
                {{articleSec.title}}
              </el-menu-item>
            </el-submenu>

          </el-submenu>
        </template>
      </el-menu>
    </el-scrollbar>
    <div class="main-container">
      <div
        class="article-breadCrumb"
        v-show="!searchShow"
      >
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item>赢商知识库</el-breadcrumb-item>
          <el-breadcrumb-item v-show="article.columnName">{{article.columnName}}</el-breadcrumb-item>
          <el-breadcrumb-item
            v-show="article.title"
            class="current-item"
          >{{article.title}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <transition name="el-zoom-in-center">
        <div :class="searchShow?'article-search article-search-long':'article-search'">
          <el-input
            placeholder="请输入关键词"
            v-model="keywordSearch"
          >
            <template slot="prepend">
              <i class="el-icon-search"></i>
            </template>
            <el-button
              slot="append"
              v-if="!searchShow||keywordSearch!=keywordSearched"
              @click="searchKnowledge"
            >搜索</el-button>
            <el-button
              slot="append"
              class="cancel-search"
              v-else
              @click="searchShow=false"
            >取消</el-button>
          </el-input>
        </div>
      </transition>
      <transition name="el-zoom-in-top">
        <div
          class="search-content"
          v-show="searchShow"
        >
          <a
            v-for="d in searchData"
            :key="d.id"
            :href="'#/knowledgeBase/'+d.id"
            @click="getKnowledgeInfo('/knowledgeBase/'+d.id)"
          >
            <div class="simpleInfoPanel">
              <p
                class="search-content-title"
                v-html="d.title"
              ></p>
              <p
                class="search-content-content"
                v-html="d.content"
              ></p>
            </div>
          </a>
          <div
            class="noData-look-brand"
            v-show="searchDataIsEmpty"
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>没有找到"<span style="color: red;">{{keywordSearched}}</span>"相关的内容，请尝试其它的关键词。</p>
          </div>
        </div>
      </transition>
      <transition name="el-fade-in-linear">
        <div
          class="article"
          v-show="!searchShow&&article.id"
        >
          <div class="article-title">{{article.title}}</div>
          <div class="article-date">更新时间：{{formateDate(article.createDate)}}</div>
          <div
            class="article-content ql-editor"
            v-html="formateVideo(article.content)"
          ></div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  name: 'knowledgeBase',
  components: {},
  data () {
    return {
      columnList: [],
      knowledgeList: [],
      key: '',
      searchShow: false,
      article: {
        columnId: '',
        content: '',
        createDate: '',
        createId: '',
        createName: '',
        delete: '',
        id: '',
        title: '',
        titleImgId: '',
        titleImgUrl: '',
        type: ''
      },
      keywordSearch: '',
      keywordSearched: '',
      searchData: [],
      searchDataIsEmpty: false,
      isFixed: false,
      scrollHeight: 122
    }
  },
  computed: {
    activeMenu () {
      const route = this.$route
      const { path } = route
      // if set path, the sidebar will highlight the path you set
      return path
    }
  },
  mounted () {
    this.getColumnList()
    this.getKnowledgeInfo()
    window.addEventListener('scroll', this.initHeight)
  },
  destroyed () {
    window.removeEventListener('scroll', this.initHeight, false)
  },
  methods: {
    // 实现吸顶效果，判断滚动条距离顶部的距离
    initHeight () {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      this.isFixed = scrollTop > this.scrollHeight
    },
    // 获取树结构目录文章
    getColumnList () {
      const params = {
        parentId: 0
      }
      this.axios.get(api.getColumnList, { params: params })
        .then((res) => {
          this.columnList = this.traverseList(res.data.columnVOList)
          console.log(this.columnList)
        })
    },
    // 遍历树据添加参数
    traverseList (data) {
      data.map(item => {
        if (Array.isArray(item.articleList)) {
          item.articleList.map(item2 => {
            item2.index = item2.id.toString()
            item2.columnName = item.name
            return item2
          })
        } else {
          item.articleList = []
        }
        if (Array.isArray(item.columnList)) {
          this.traverseList(item.columnList)
        } else {
          item.columnList = []
        }
        item.index = item.id.toString()
        return item
      })
      return data
    },
    // 获取文章详情
    getKnowledgeInfo (key) {
      if (!key) {
        key = this.$route.path
      }
      const index = key.replace(/\//g, '').replace(/knowledgeBase/g, '')
      if (index) {
        this.searchShow = false
        this.axios.get(api.getKnowledgeInfo, { params: { id: index } })
          .then((res) => {
            this.toTop()
            this.article = res.data.article
            this.getColumnName(this.columnList, this.article.columnId)
          })
      }
    },
    // 返回头部
    toTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    },
    // 遍历树据添加参数
    getColumnName (data, columnId) {
      data.forEach(item => {
        if (item.id === columnId) {
          this.article.columnName = item.name
        } else if (Array.isArray(item.columnList)) {
          this.getColumnName(item.columnList, columnId)
        }
      })
    },
    // 根据关键字搜索相关内容
    searchKnowledge () {
      this.searchShow = true
      this.keywordSearched = this.keywordSearch
      this.searchDataIsEmpty = false
      this.searchData = []
      this.axios.get(api.searchKnowledge, { params: { keyword: this.keywordSearch } })
        .then((res) => {
          this.searchData = res.data.page
          if (this.searchData.length === 0) {
            this.searchDataIsEmpty = true
          }
        })
    },
    formateVideo (content) {
      const t = content.replace('<iframe class="ql-video"', '<video class="ql-video" controls="" autoplay=""').replace('</iframe>', '</video>')
      return t
    },
    formateDate (date) {
      const d = date.replace('T', ' ')
      return d
    }
  }
}
</script>

<style lang="stylus">
.isFixedTop
  position fixed
  top 0
.article-content
  img
    max-width 100%
  *
    font-size 16px
    line-height 26px
</style>

<style scoped lang="stylus">
.knowledgeBase
  min-height 699px
  overflow-y scroll
.knowledgeBase::-webkit-scrollbar
  display none
.knowledgeBase>>>
  .sidebar-container
    transition width 0.28s
    width 272px !important
    height calc(100vh - 140px)
    min-height 684px
    background-color #23252b
    margin-top 15px
    float left
    overflow hidden
    .scrollbar-wrapper
      overflow-x hidden
      overflow-y scroll
    .el-menu
      background-color #23252b
      border-right none
      margin-top 20px
      li.el-submenu__title:hover
        background-color #33343a !important
      .el-menu-item, .el-submenu__title
        font-size 16px
        color #fff
        border-left 3px solid #23252b
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
        &:hover
          border-left 3px solid #FFA134
          background-color #33343a !important
          color #FFA134 !important
      .columnSec
        font-size 14px
      .el-menu-item
        font-size 14px
        font-weight 400
        color #FFFFFF80
        padding-right 20px
      .is-active
        border-left 3px solid #FFA134
        background-color #33343a
        color #FFA134 !important
      .is-opened
        border none
        background none
    .el-submenu
      .el-menu
        margin 0
  .main-container
    transition margin-left 0.28s
    color #fff
    padding-top 15px
    margin-left 275px
    position relative
    .article-breadCrumb
      width 30%
      line-height 50px
      float left
      padding 10px
      .current-item
        .el-breadcrumb__inner
          color #fff
    .article-search
      width 557px
      float right
      margin-right 24px
      background #272930
      box-shadow 0px 7px 35px 0px rgba(0, 0, 0, 0.43)
      border-radius 3px
      right 0
      .el-input-group__prepend, .el-input__inner, .el-input-group__append
        background #272930
        border 0
        height 36px
        border-radius 0
        color #ffffff
      .el-input-group__append
        background #35363d
      .cancel-search
        color #FFA134
    .article-search-long
      width calc(100% - 34px)
    .article
      width 825px
      margin 100px auto
      .article-content
        width 100%
        margin 50px 0 0 0
        padding 0
      .article-title
        width 100%
        margin 15px auto
        height 24px
        font-size 24px
        font-weight 500
        color #FFFFFF
      .article-date
        width 100%
        margin 0 auto
        height 12px
        font-size 12px
        font-weight 400
        color #A5A5A7
    .search-content
      margin-top 50px
      padding 10px
      .noData-look-brand
        text-align center
      .simpleInfoPanel
        padding 32px 43px 32px 32px
        color #FFFFFF
        margin-bottom 16px
        width calc(100% - 85px)
        cursor pointer
        .search-content-title
          font-size 24px
          font-weight 500
          margin-bottom 22px
        .search-content-content
          font-size 14px
          font-weight 400
          line-height 24px
</style>
